import React from 'react' 
const Navigation = () => {
    return (
        <>
    
        <button id="scroll_top" className="scroll-top">
          <i className="fa-solid fa-arrow-up" />
        </button>
        {/* Offcanvas area start */}
        <div className="offcanvas__area">
          <div className="offcanvas__inner">
            <div className="offcanvas__top">
              <img
                src="https://wowtheme7.com/tf/bizzpro/assets/imgs/logo/logo2.png"
                alt="Logo"
              />
              <button id="offcanvas_close">
                <i className="fa-solid fa-xmark" />
              </button>
            </div>
            <div className="offcanvas__search">
              <form action="#">
                <input type="text" placeholder="Search..." name="s" />
              </form>
            </div>
            <div className="offcanvas__menu">
              <div className="offcanvas-menu">
                <ul>
                  <li>
                    <a>Home</a>
                    <ul>
                      <li>
                        <a >Home 01</a>
                      </li>
                      <li>
                        <a >Home 02</a>
                      </li>
                      <li>
                        <a >Home 03</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a >About</a>
                  </li>
                  <li>
                    <a>Service</a>
                    <ul>
                      <li>
                        <a >Service</a>
                      </li>
                      <li>
                        <a>Service details</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a >Pages</a>
                    <ul>
                      <li>
                        <a>portfolio</a>
                      </li>
                      <li>
                        <a>portfolio details</a>
                      </li>
                      <li>
                        <a>team</a>
                      </li>
                      <li>
                        <a>team details</a>
                      </li>
                      <li>
                        <a>faq</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a>blog</a>
                    <ul>
                      <li>
                        <a>Blog</a>
                      </li>
                      <li>
                        <a >Blog Grid</a>
                      </li>
                      <li>
                        <a >Blog details</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a >Contact</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="offcanvas__map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d1511.2499674845235!2d-73.99553882767792!3d40.75102778252164!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1686536419224!5m2!1sen!2sbd"
                loading="lazy"
              />
            </div>
            <div className="offcanvas__btm">
              <div className="footer__address-3">
                <ul>
                  <li>
                    <span>
                      <i className="fa-solid fa-location-dot" />
                    </span>
                    <p className="text-white">
                      Burmsille Street, MN 55337, <br /> United States{" "}
                    </p>
                  </li>
                  <li>
                    <span>
                      <i className="fa-solid fa-phone" />
                    </span>
                    <div>
                      <a href="tel:+88014420420">+(1) 123 456 7890</a>
                      <a href="tel:+88014420420">+(1) 098 765 4321</a>
                    </div>
                  </li>
                  <li>
                    <span>
                      <i className="fa-solid fa-envelope" />
                    </span>
                    <div>
                      <a href="mailto:info@driller.com">info@driller.com</a>
                      <a href="mailto:info.example@driller.com">
                        info.example@driller.com
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="footer__social-3">
                <ul>
                  <li>
                    <a>
                      <i className="fa-brands fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a>
                      <i className="fa-brands fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a>
                      <i className="fa-brands fa-instagram" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Offcanvas area end */}
        
       
 
      </>
      
    )
}
 
export default Navigation