import React from 'react';

 
const Home = () => {
    return (
      <main>
      {/* Hero area start */}
      <section
        className="hero__area_1"
        style={{
          backgroundImage:
            'url("https://wowtheme7.com/tf/bizzpro/assets/imgs/hero/00.png")'
        }}
      >
        <div className="hero__area_1_inner">
          <div className="hero_1_content">
            <h1 className="hero__title heading-animation">
              {" "}
              Experience{" "}
              <img
                className="cxuSpin-slow"
                src="https://wowtheme7.com/tf/bizzpro/assets/imgs/hero/02.png"
                alt="img"
              />
              <br /> the <span>power of agency</span>
            </h1>
            <div className="video_area" data-lag="0.3">
              <a
                href="https://www.youtube.com/watch?v=FYpsf0ACkmI"
                className="popup-link"
              >
                <img
                  className="cxuSpin-slow2"
                  src="https://wowtheme7.com/tf/bizzpro/assets/imgs/hero/03.png"
                  alt="Hero Image"
                />
                <i className="fa-solid fa-play" />
              </a>
            </div>
          </div>
          <div className="main-thumb text-end">
            <img
              className="main-img"
              src="https://wowtheme7.com/tf/bizzpro/assets/imgs/hero/01.png"
              alt="Hero Image"
            />
          </div>
        </div>
      </section>
      {/* Hero area end */}
      {/* Service area start */}
      <section className="service__area pt-150 pb-130">
        <div className="container">
          <div className="bg-light1 border-radius-25 pt-120 px-lg-5 px-4 pb-90 pb-120">
            <div className="row pb-120">
              <div className="col-xxl-7 col-xl-7 col-xl-7">
                <div className="service__title-wrap" data-aos="fade-up">
                  <h2 className="sec-subtitle">
                    best service <span>best service</span>
                  </h2>
                  <h3 className="sec-title heading-animation">
                    Innovative solutions for <br /> a digital world{" "}
                  </h3>
                </div>
              </div>
              <div className="col-xxl-5 col-xl-5 col-xl-5">
                <div className="service__text p-animation pt-0">
                  <p className="ms-0">
                    Which is the same as saying through shrinking from toil
                    and pain in These cases are many perfectly simple and easy
                    to distinguish thiIn a free hour this when
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="service-inner px-lg-5 px-4">
            <div className="row pt-4">
              <div className="col-xxl-4 col-xl-4 col-xl-4 col-md-6 mb-4">
                <div className="service__item style2">
                  <div className="service__content">
                    <div className="icon-box">
                      <img
                        src="https://wowtheme7.com/tf/bizzpro/assets/imgs/service/4/4.png"
                        alt="Service Icon"
                      />
                    </div>
                    <a href="service-details.html">
                      <div className="service__title">
                        Pixel Perfect Design
                      </div>
                    </a>
                    <p>
                      Health and medical is a encompasses a wide range of
                      topics related to health
                    </p>
                    <a className="db-btn-arrow" href="service-details.html">
                      Read More <i className="fa-solid fa-arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-xl-4 col-md-6 mb-4">
                <div className="service__item style2">
                  <div className="service__content">
                    <div className="icon-box">
                      <img
                        src="https://wowtheme7.com/tf/bizzpro/assets/imgs/service/4/5.png"
                        alt="Service Icon"
                      />
                    </div>
                    <a href="service-details.html">
                      <div className="service__title">
                        Creative Edge Solutions
                      </div>
                    </a>
                    <p>
                      Health and medical is a encompasses a wide range of
                      topics related to health
                    </p>
                    <a className="db-btn-arrow" href="service-details.html">
                      Read More <i className="fa-solid fa-arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-xl-4 col-md-6 mb-4">
                <div className="service__item style2">
                  <div className="service__content">
                    <div className="icon-box">
                      <img
                        src="https://wowtheme7.com/tf/bizzpro/assets/imgs/service/4/6.png"
                        alt="Service Icon"
                      />
                    </div>
                    <a href="service-details.html">
                      <div className="service__title">
                        The Pixel Producers
                      </div>
                    </a>
                    <p>
                      Health and medical is a encompasses a wide range of
                      topics related to health
                    </p>
                    <a className="db-btn-arrow" href="service-details.html">
                      Read More <i className="fa-solid fa-arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Service area end */}
      {/* About area start */}
      <section className="about__area pb-5">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-xl-6 col-md-6">
              <div className="about__imgs" data-aos="fade-up">
                <div className="image-1">
                  <img
                    className="border-radius-25"
                    src="https://wowtheme7.com/tf/bizzpro/assets/imgs/about/1.png"
                    alt="Image"
                  />
                </div>
                <div className="image-2">
                  <img
                    src="https://wowtheme7.com/tf/bizzpro/assets/imgs/about/3.png"
                    alt="Image"
                    data-speed="0.85"
                  />
                </div>
                <div className="bell">
                  <span>
                    <i className="fa-regular fa-bell text-white" />
                  </span>
                </div>
                <div className="projects">
                  <img
                    src="https://wowtheme7.com/tf/bizzpro/assets/imgs/icon/medal.png"
                    alt="Medal Icon"
                  />
                  <p>
                    <span className="counter_fast count">230+</span>Project Complete
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-xl-6 col-md-6">
              <div
                className="about__content"
                data-aos="fade-up"
                data-aos-delay={500}
              >
                <h2 className="sec-subtitle">
                  ABOUT US <span>About Us</span>
                </h2>
                <h3 className="sec-title heading-animation">
                  Innovative solutions for a digital world
                </h3>
                <div className="p-animation">
                  <p>
                    Which is the same as saying through shrinking from toil
                    and pain. These cases are many perfectly simple and easy
                    to distinguish. In a free hour this when our power of
                    choice is untrammelled and when nothing prevents
                  </p>
                </div>
                <ul data-aos="fade-up">
                  <li>How to improve your busines for Helping us </li>
                  <li>
                    making this the first true generator on the Internet
                  </li>
                  <li>
                    Creative this the first true generator on the designe
                  </li>
                </ul>
                <div data-aos="fade-up" data-aos-delay={300}>
                  <a className="db-btn-arrow" href="about.html">
                    Read More <i className="fa-solid fa-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <img
            src="https://wowtheme7.com/tf/bizzpro/assets/imgs/shape/6.png"
            alt="Zigzag Shape"
            className="shape"
          />
        </div>
      </section>
      {/* About area end */}
      {/* Counter area start */}
      <section className="counter__area bg-white">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="counter__inner">
                <div className="counter__item" data-aos="fade-right" data-aos-delay={600}>
                  <div className="counter__number counter_slow style2 count">
                    200+
                  </div>
                  <h3 className="counter__title color-heading">
                    Happy customer{" "}
                  </h3>
                </div>
                <div
                  className="counter__item"
                  data-aos="fade-right"
                  data-aos-delay={300}
                >
                  <div className="counter__number counter_slow style2 count">
                    300+
                  </div>
                  <h3 className="counter__title color-heading">
                    total branches{" "}
                  </h3>
                </div>
                <div
                  className="counter__item"
                  data-aos="fade-right"
                  
                >
                  <div className="counter__number counter_slow style2 count">
                    550+
                  </div>
                  <h3 className="counter__title color-heading">
                    Project complete{" "}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Counter area end */}
      {/* intro area start */}
      <div className="intro-area bg-light1 pt-150">
        <div className="container pb-130">
          <div className="pt-0 mb-5" data-aos="fade-up" data-aos-delay={500}>
            <h2 className="sec-subtitle">
              Our Intro <span>Our Intro</span>
            </h2>
            <h3 className="sec-title heading-animation">
              Story with passion
            </h3>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="single-intro-inner">
                <div className="thumb">
                  <img
                    className="w-100"
                    src="https://wowtheme7.com/tf/bizzpro/assets/imgs/intro/5.png"
                    alt="img"
                  />
                </div>
                <div className="details">
                  <div className="icon-box">
                    <img
                      src="https://wowtheme7.com/tf/bizzpro/assets/imgs/intro/1.png"
                      alt="img"
                    />
                  </div>
                  <span />
                  <p>Our services</p>
                  <h5>Regular update</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-intro-inner">
                <div className="thumb">
                  <img
                    className="w-100"
                    src="https://wowtheme7.com/tf/bizzpro/assets/imgs/intro/6.png"
                    alt="img"
                  />
                </div>
                <div className="details">
                  <div className="icon-box">
                    <img
                      src="https://wowtheme7.com/tf/bizzpro/assets/imgs/intro/2.png"
                      alt="img"
                    />
                  </div>
                  <span />
                  <p>Our Update</p>
                  <h5>Work From Home</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-intro-inner">
                <div className="thumb">
                  <img
                    className="w-100"
                    src="https://wowtheme7.com/tf/bizzpro/assets/imgs/intro/7.png"
                    alt="img"
                  />
                </div>
                <div className="details">
                  <div className="icon-box">
                    <img
                      src="https://wowtheme7.com/tf/bizzpro/assets/imgs/intro/3.png"
                      alt="img"
                    />
                  </div>
                  <span />
                  <p>With Humble</p>
                  <h5>Work With Humble</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-intro-inner">
                <div className="thumb">
                  <img
                    className="w-100"
                    src="https://wowtheme7.com/tf/bizzpro/assets/imgs/intro/8.png"
                    alt="img"
                  />
                </div>
                <div className="details">
                  <div className="icon-box">
                    <img
                      src="https://wowtheme7.com/tf/bizzpro/assets/imgs/intro/4.png"
                      alt="img"
                    />
                  </div>
                  <span />
                  <p>the power</p>
                  <h5>Clean Need Project</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="exp-area"
          style={{
            backgroundImage:
              'url("https://wowtheme7.com/tf/bizzpro/assets/imgs/intro/9.png")'
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="pt-5 pb-5 about__content pt-0"
                  data-aos="fade-up"
                  data-aos-delay={500}
                >
                  <h3 className="sec-title heading-animation">
                    Experience the power of transformation
                  </h3>
                  <p className="border-bottom-1-black mb-lg-5 mb-4 pb-lg-5 pb-4">
                    There are many variations of passages of Lorem Ipsum
                    available ours but the majority have suffered alteration
                    in some form, by injected humour or randomised words which
                    don look even the design here us
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <ul>
                        <li className="pb-3">
                          Be positive but realistic designer{" "}
                        </li>
                        <li className="pb-3">Know your target market </li>
                        <li className="pb-3">
                          Simplify your processes creative{" "}
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul>
                        <li className="pb-3">How to improve your busines</li>
                        <li className="pb-3">making this the first true</li>
                        <li className="pb-3">Creative this the first true</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* intro area end */}
      {/* Contact area start */}
      <section className="contact__areas pt-150 pb-130">
        <div className="container">
          <div className="box-shadow p-xl-5 p-4 border-radius-50">
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 mb-lg-0 mb-4">
                <img
                  className="border-radius-25"
                  src="https://wowtheme7.com/tf/bizzpro/assets/imgs/intro/10.png"
                  alt="img"
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 align-self-center">
                <div className="contact__form contact__form-5 mt-0 pt-0 pb-0">
                  <div className="" data-aos="fade-up" data-aos-delay={600}>
                    <form action="#">
                      <input
                        type="text"
                        name="name"
                        placeholder="Name*"
                        required=""
                      />
                      <input
                        type="email"
                        name="email"
                        placeholder="E-mail*"
                        required=""
                      />
                      <input
                        type="text"
                        name="subject"
                        placeholder="subject*"
                        required=""
                      />
                      <textarea
                        name="comments"
                        placeholder="Comment*"
                        required=""
                        defaultValue={""}
                      />
                      <button
                        className="submit btn-rollover bg-base"
                        type="submit"
                      >
                        Submit <i className="fa-solid fa-arrow-right" />
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Contact area end */}
      {/* Text Slider area start */}
      <section className="textslider__area bg-white p-0">
        <div className="textslider__inner">
          <div className="swiper textslider__active textslider_1">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="textslider__slide">
                  <h2>
                    Unleash U <span>-</span>
                  </h2>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="textslider__slide">
                  <h2>
                    Business <span>-</span>
                  </h2>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="textslider__slide">
                  <h2>
                    Potential <span>-</span>
                  </h2>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="textslider__slide">
                  <h2>
                    Experience <span>-</span>
                  </h2>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="textslider__slide">
                  <h2>
                    Unleash U <span>-</span>
                  </h2>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="textslider__slide">
                  <h2>
                    Consult <span>-</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="textslider__area bg-white p-0">
        <div className="textslider__inner">
          <div className="swiper textslider__active-3 textslider_2">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="textslider__slide">
                  <h2>
                    Unleash U <span>-</span>
                  </h2>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="textslider__slide">
                  <h2>
                    Business <span>-</span>
                  </h2>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="textslider__slide">
                  <h2>
                    Potential <span>-</span>
                  </h2>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="textslider__slide">
                  <h2>
                    Experience <span>-</span>
                  </h2>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="textslider__slide">
                  <h2>
                    Unleash U <span>-</span>
                  </h2>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="textslider__slide">
                  <h2>
                    Consult <span>-</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Text Slider area end */}
      {/* FAQs area start */}
      <section className="faq__area bg-white pt-150 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-xl-6">
              <div className="sec-title-wrap mb-5 mb-lg-0" data-aos="fade-up">
                <h2 className="sec-subtitle">
                  Some Faq <span>Some Faq</span>
                </h2>
                <h3 className="sec-title heading-animation">
                  Experience the <br /> power
                </h3>
                <div className="p-animation">
                  <p className="color-heading mb-5">
                    Et purus duis sollicitudin dignissim habitant. <br />{" "}
                    Egestas nulla quis venenatis cras sed eu massa eu <br />{" "}
                    faucibus. Urna fusce aenean tortor{" "}
                  </p>
                  <a
                    className="bg-base btn-rollover border-radius-25"
                    href="#"
                  >
                    LEARN MORE
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-xl-6">
              <div className="faq__list">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item style-2" data-aos="fade-up">
                    <h2 className="" id="headingOne">
                      <button
                        className="accordion-button color-heading"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        {" "}
                        How much agencies charge for their services ?{" "}
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p className="color-heading">
                          It is a long established fact that a reader will be
                          distr acted bioiiy the rea dablea content of a page
                          when looking at its layout Thoiie point of using.It
                          is a long this established fact that a reader
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="accordion-item style-2"
                    data-aos="fade-up"
                    data-aos-delay={300}
                  >
                    <h2 className="" id="headingTwo">
                      <button
                        className="color-heading accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        {" "}
                        How long does i digital agency to complete a project ?{" "}
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p className="color-heading">
                          This is the third item's accordion body. It is
                          hidden by default, until the collapse plugin adds
                          the appropriate classes that we use to style each
                          element. These classes control the overall
                          appearance, as well as the showing and hiding via
                          CSS transitions.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="accordion-item style-2"
                    data-aos="fade-up"
                    data-aos-delay={600}
                  >
                    <h2 className="" id="headingThree">
                      <button
                        className="color-heading accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        {" "}
                        What services do digital agencies typically offer ?{" "}
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p className="color-heading">
                          This is the third item's accordion body. It is
                          hidden by default, until the collapse plugin adds
                          the appropriate classes that we use to style each
                          element.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="accordion-item style-2"
                    data-aos="fade-up"
                    data-aos-delay={900}
                  >
                    <h2 className="" id="headingFour">
                      <button
                        className="color-heading accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        {" "}
                        How do I know if a digital agency is business ?{" "}
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p className="color-heading">
                          This is the third item's accordion body. It is
                          hidden by default, until the collapse plugin adds
                          the appropriate classes that we use to style each
                          element.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* FAQs area end */}
      {/* Who we are area start */}
      <section
        className="who__area"
        style={{
          backgroundImage:
            'url("https://wowtheme7.com/tf/bizzpro/assets/imgs/hero/00.png")'
        }}
      >
        <div
          className="who__area_inner pt-5 pb-5"
          style={{
            backgroundImage:
              'url("https://wowtheme7.com/tf/bizzpro/assets/imgs/intro/11.png")'
          }}
        >
          <div className="container mt-xl-5 mb-xl-5">
            <div className="row">
              <div className="col-xxl-5 col-xl-5 col-lg-6">
                <div className="who__left">
                  <div className="sec-title-wrap pb-3" data-aos="fade-up">
                    <h2 className="sec-subtitle">
                      who we are <span>who we are</span>
                    </h2>
                    <h3 className="sec-title heading-animation">
                      Transforming ideas <br /> into reality{" "}
                    </h3>
                  </div>
                  <div className="who__features">
                    <div
                      className="who__feature"
                      data-aos="fade-up"
                      data-aos-delay={300}
                    >
                      <div className="number">
                        <span className="color-base">01</span>
                      </div>
                      <div className="who__content">
                        <h4 className="title">Creative Craze</h4>
                        <p>
                          Health and medical is a encompasses a wide range of
                          topics related to health
                        </p>
                      </div>
                    </div>
                    <div
                      className="who__feature"
                      data-aos="fade-up"
                      data-aos-delay={600}
                    >
                      <div className="number">
                        <span className="color-base">02</span>
                      </div>
                      <div className="who__content">
                        <h4 className="title">Art on Mine</h4>
                        <p>
                          Health and medical is a encompasses a wide range of
                          topics related to health
                        </p>
                      </div>
                    </div>
                    <div
                      className="who__feature"
                      data-aos="fade-up"
                      data-aos-delay={900}
                    >
                      <div className="number">
                        <span className="color-base">03</span>
                      </div>
                      <div className="who__content">
                        <h4 className="title">Agencey Power</h4>
                        <p>
                          Health and medical is a encompasses a wide range of
                          topics related to health
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Who we are area end */}
      {/* Newsletter area start */}
      <section className="newsletter__area bg-light1 pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-xxl-5 col-xl-5 col-lg-5 align-self-center">
              <h2 className="sec-subtitle">
                Newsletter <span>Newsletter</span>
              </h2>
              <h3 className="sec-title heading-animation pb-0">
                Want to Be a Member?
              </h3>
            </div>
            <div className="col-xxl-7 col-xl-7 col-lg-7">
              <div className="newsletter__right ps-xxl-5">
                <form action="#" className="ps-xxl-5">
                  <input
                    className="border-radius-50"
                    type="email"
                    name="email"
                    placeholder="Enter Your Email"
                  />
                  <button
                    type="submit"
                    className="submit btn-rollover border-radius-50 border-radius-50-hover bg-base"
                  >
                    Subscribe Now
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Newsletter area end */}
      {/* Blog area start */}
      <section className="blog__area pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="sec-title-wrap" data-aos="fade-up">
                <h2 className="sec-subtitle">
                  Our Blog <span>Our Blog</span>
                </h2>
                <h3 className="sec-title heading-animation">
                  Let’s Checkout our All <br /> Letest News{" "}
                </h3>
              </div>
            </div>
            <div className="col-xxl-12">
              <div className="blog__list">
                <article
                  className="blog__item"
                  data-aos="fade-up"
                  data-aos-delay={300}
                >
                  <div className="blog__thumb">
                    <div className="shine">
                      <a href="blog-details.html">
                        <img
                          className="border-radius-25"
                          src="https://wowtheme7.com/tf/bizzpro/assets/imgs/blog/1.png"
                          alt="Thumbnail"
                        />
                      </a>
                    </div>
                    <div className="date">
                      16 <br /> March{" "}
                    </div>
                  </div>
                  <ul className="blog__meta">
                    <li>
                      <a href="#">
                        <i className="fa-regular fa-user" /> By admin{" "}
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa-regular fa-comments" /> Comments (05){" "}
                      </a>
                    </li>
                  </ul>
                  <a href="blog-details.html">
                    <h4 className="blog__title">
                      Standard chunk of Lorem Ipsum Our Company
                    </h4>
                  </a>
                  <a className="db-btn-arrow" href="blog-details.html">
                    Read More <i className="fa-solid fa-arrow-right" />
                  </a>
                </article>
                <article
                  className="blog__item"
                  data-aos="fade-up"
                  data-aos-delay={600}
                >
                  <div className="blog__thumb">
                    <div className="shine">
                      <a href="blog-details.html">
                        <img
                          className="border-radius-25"
                          src="https://wowtheme7.com/tf/bizzpro/assets/imgs/blog/2.png"
                          alt="Thumbnail"
                        />
                      </a>
                    </div>
                    <div className="date">
                      16 <br /> March{" "}
                    </div>
                  </div>
                  <ul className="blog__meta">
                    <li>
                      <a href="">
                        <i className="fa-regular fa-user" /> By admin{" "}
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <i className="fa-regular fa-comments" /> Comments (05){" "}
                      </a>
                    </li>
                  </ul>
                  <a href="blog-details.html">
                    <h4 className="blog__title">
                      These cases are many simple and easy
                    </h4>
                  </a>
                  <a className="db-btn-arrow" href="blog-details.html">
                    Read More <i className="fa-solid fa-arrow-right" />
                  </a>
                </article>
                <article
                  className="blog__item"
                  data-aos="fade-up"
                  data-aos-delay={900}
                >
                  <div className="blog__thumb">
                    <div className="shine">
                      <a href="blog-details.html">
                        <img
                          className="border-radius-25"
                          src="https://wowtheme7.com/tf/bizzpro/assets/imgs/blog/3.png"
                          alt="Thumbnail"
                        />
                      </a>
                    </div>
                    <div className="date">
                      16 <br /> March{" "}
                    </div>
                  </div>
                  <ul className="blog__meta">
                    <li>
                      <a href="">
                        <i className="fa-regular fa-user" /> By admin{" "}
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <i className="fa-regular fa-comments" /> Comments (05){" "}
                      </a>
                    </li>
                  </ul>
                  <a href="blog-details.html">
                    <h4 className="blog__title">
                      The standard chunk of Ipsum Our Company
                    </h4>
                  </a>
                  <a className="db-btn-arrow" href="blog-details.html">
                    Read More <i className="fa-solid fa-arrow-right" />
                  </a>
                </article>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Blog area end */}
    </main>
    )
}
 
export default Home 