import Home from './Components/Home';
import Navigation from './Components/navigation';
import Footer from './Components/footer';

 
function App() {
    return (
        <>
        <Navigation/>
        <Home/>
        <Footer/>
        
        </>
    );
}
 
export default App;