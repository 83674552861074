import React from 'react';

 
const Footer = () => {
    return (
        <>
<div id="smooth-wrapper">
          <div id="smooth-content">
            {/* Header area start */}
            <header className="header__area pos-abs plr-100">
              <div className="header__inner">
                <div className="header__logo">
                  <a href="index.html">
                    <img
                      src="https://wowtheme7.com/tf/bizzpro/assets/imgs/logo/logo.png"
                      alt="Site Logo"
                    />
                  </a>
                </div>
                <div className="header__menu">
                  <nav className="main-menu">
                    <ul>
                      <li className="has-dropdown">
                        <a >Home</a>
                        <ul className="main-dropdown">
                          <li>
                            <a >Home 01</a>
                          </li>
                          <li>
                            <a >Home 02</a>
                          </li>
                          <li>
                            <a >Home 03</a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a>About</a>
                      </li>
                      <li className="has-dropdown">
                        <a >Service</a>
                        <ul className="main-dropdown">
                          <li>
                            <a >Service</a>
                          </li>
                          <li>
                            <a >Service details</a>
                          </li>
                        </ul>
                      </li>
                      <li className="has-megamenu">
                        <a href="#">pages</a>
                        <ul className="main-dropdown">
                          <li>
                            <a >portfolio</a>
                          </li>
                          <li>
                            <a >portfolio details</a>
                          </li>
                          <li>
                            <a >team</a>
                          </li>
                          <li>
                            <a >team details</a>
                          </li>
                          <li>
                            <a >faq</a>
                          </li>
                        </ul>
                      </li>
                      <li className="has-dropdown">
                        <a >blog</a>
                        <ul className="main-dropdown">
                          <li>
                            <a href="blog.html">Blog</a>
                          </li>
                          <li>
                            <a >Blog Grid</a>
                          </li>
                          <li>
                            <a >blog details</a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a >Contact</a>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="header__others">
                  <div className="header__search">
                    <button className="search-icon">
                      <i className="fa-solid fa-magnifying-glass" />
                    </button>
                    <button className="search-close">
                      <i className="fa-solid fa-xmark" />
                    </button>
                  </div>
                  <div className="header__offcanvas">
                    <button className="menu_icon bg-base">
                      <img
                        src="https://wowtheme7.com/tf/bizzpro/assets/imgs/icon/menu.png"
                        alt="Menu Icon"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </header>
            <div className="search__form">
              <form action="#">
                <input type="text" name="s" placeholder="Search..." />
              </form>
            </div>
            {/* Header area end */}
           
            {/* Footer area start */}
            <footer className="footer__area footer_two_area">
              <div className="footer__widgets">
                <div className="container">
                  <div className="footer_top">
                    <div className="row">
                      <div className="col-lg-7">
                        <h6 className="text-white">Have an idea?</h6>
                        <h2 className="text-white mb-4 mb-lg-0">
                          Get in <span>touch</span>
                        </h2>
                      </div>
                      <div className="col-md-5 text-lg-end">
                        <a data-lag="0.3" href="contact.html">
                          Get a quote <i className="fa fa-arrow-right" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
                      <div className="footer__logo">
                        <img
                          src="https://wowtheme7.com/tf/bizzpro/assets/imgs/logo/logo2.png"
                          alt="Footer Logo"
                        />
                        <p>
                          <strong>bill.sanders@example.com</strong>
                        </p>
                        <p>© Yoursitename 2024 | All Rights Reserved</p>
                      </div>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
                      <div className="footer__service">
                        <h2 className="footer__title">Useful Links</h2>
                        <ul>
                          <li>
                            <a href="about.html">About Us</a>
                          </li>
                          <li>
                            <a href="about.html">Our Team</a>
                          </li>
                          <li>
                            <a href="about.html">Recent News </a>
                          </li>
                          <li>
                            <a href="about.html">Project</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
                      <div className="footer__service">
                        <h2 className="footer__title">More Servicve</h2>
                        <ul>
                          <li>
                            <a href="about.html">Ui Design</a>
                          </li>
                          <li>
                            <a href="about.html">Ux Design</a>
                          </li>
                          <li>
                            <a href="about.html">Digital Marketing</a>
                          </li>
                          <li>
                            <a href="about.html">Video Editing</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
                      <div className="footer__address">
                        <h2 className="footer__title">Address</h2>
                        <ul>
                          <li>
                            <span>
                              <i className="fa-solid fa-paper-plane" />
                            </span>
                            <p>
                              Old city Street,Usa <br />
                              1212 New york-3500
                            </p>
                          </li>
                          <li>
                            <span>
                              <i className="fa-solid fa-phone" />
                            </span>
                            <a href="tel:+88014420420">+88 014 420420</a>
                          </li>
                          <li>
                            <span>
                              <i className="fa-solid fa-envelope" />
                            </span>
                            <a href="mailto:urcompany@mail.com">urcompany@mail.com</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
            {/* Footer area end */}
          </div>
        </div>
        </>
            )
        }
         
        export default Footer 